import React, { useMemo } from "react";
import { DEFAULT_TRANSFER_ORDER_CONTAINER_OVERVIEW_TABLE_HEADERS } from "./data/constants";
import { Header, Item } from "../../../../components/paginated-list-table-with-types";
import { DetailsPageTable } from "../../../details-page";
import { TransferOrderItem } from "../../../transfer-order-details-page/data/types";
import { TRANSFER_STATUS_MAP } from "../../../kiosk/features/selected-kiosk-feature/features/transfer-order-actions/data/constants";

type TransferOrderContainerOverviewTableOptions = {
  csvFileName?: string;
  headers?: Header[];
};

interface TransferOrderContainerOverviewTableProps {
  items: TransferOrderItem[];
  options: TransferOrderContainerOverviewTableOptions;
}

export const TransferOrderContainerOverviewTable = ({ items, options }: TransferOrderContainerOverviewTableProps) => {
  const { headers = DEFAULT_TRANSFER_ORDER_CONTAINER_OVERVIEW_TABLE_HEADERS } = options || {};

  const stageCounts: Item[] = useMemo(() => {
    const counts = items.reduce((acc: Record<string, number>, item) => {
      acc[item.transferStatus] = (acc[item.transferStatus] || 0) + 1;

      return acc;
    }, {});

    return Object.entries(counts).map(([transferStatus, count]) => {
      const status = TRANSFER_STATUS_MAP[transferStatus];
      return {
        id: transferStatus,
        status: status?.label || "Unknown",
        statusSortValue: status?.sortIndex || 0,
        count
      };
    });
  }, [items]);

  return (
    <DetailsPageTable
      paginatorLocation="none"
      headers={headers}
      data={stageCounts}
      exportEnabled={false}
      textSearchEnabled={false}
      sortIdReplacementMap={{
        status: "statusSortValue"
      }}
    />
  );
};
