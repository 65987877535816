import React from "react";
import queryString from "query-string";
import InputLabel from "@material-ui/core/InputLabel";
import TextareaAutosize from "react-textarea-autosize";
import { DropdownItem } from "mdbreact";
import MarkAsCompletedActionModal from "../mark-as-completed-action-modal";
import SearchDropdown from "../../components/SearchDropdown/SearchDropdown";
import { multipleSortComparison } from "../../utils";
import { XemelgoService } from "../../services/XemelgoService";
import ConfigurationService from "../../services/ConfigurationService";
import LoadingCircle from "../../components/loading/LoadingCircle";
import Style from "./css/AssetDetailPage.module.css";
import DetailCard from "../../components/TrackPageComponents/DetailCard";
import DataTable from "../../components/TrackPageComponents/DataTable";
import NotFoundComponent from "../../components/not-found-component/NotFoundComponent";
import { ReactComponent as AssetIcon } from "../../assets/icons/asset.svg";
import { LocalCacheService } from "../../services/local-cache-service";
import ScreenFrame from "../../components/screen-frame";
import xemelgoStyle from "../../styles/variable";
import {
  getFormattedDate,
  msToTime,
  getStatusFlags,
  getAttributeList,
  formatProcessStateRoute,
  uploadImagesToS3
} from "../../common/Utilities";
import ReportIssueModal from "../../components/report-issue-modal";
import ModifyItemModal from "../modify-item-modal";
import ItemRouteFeature from "../item-route-feature";
import StatusPopupComponent from "../../components/status-popup-component";
import { DEFAULT_POPUP_DURATION_MS, STATUS_OPTIONS } from "../../components/status-popup-component/data/constants";
import IssueHistoryFeature from "../issue-history-feature";
import getStatusFlagsWithIssues from "../../utils/get-status-flags-with-issues";
import Modal from "../../components/modal";
import { SOLUTIONS } from "../../data/constants";
import updateAssetDetails from "./utils/update-asset-details";

export default class AssetDetailPage extends React.Component {
  constructor(props) {
    super(props);

    const userProfile = LocalCacheService.loadUserProfile();

    this.state = {
      titleStructure: {},
      contentStructureList: [],
      assetState: {},
      route: [],
      locations: [],
      allLocations: [],
      routeTitleList: [],
      itemId: "",
      addLocation: false,
      locationToAdd: {},
      commentBoxText: "",
      loading: true,
      showReprintModal: false,
      showReportIssueModal: false,
      isSubmitting: false,
      processStateHistory: false,
      processStateRoute: [],
      tenantId: userProfile.getTenantId(),
      modifyModalTitle: "Replace Tag",
      isModifyModalEnabled: false,
      isReplaceTagEnabled: false,
      popupStatus: STATUS_OPTIONS.NONE,
      popupMessage: "",
      statusMessage: "",
      showMarkAsCompletedModal: false,
      reuseTrackerSerial: true
    };

    this.renderDeleteAssetModal = this.renderDeleteAssetModal.bind(this);
  }

  componentDidMount() {
    const values = queryString.parse(window.location.search);
    const { itemId } = values;
    this.onLoad(itemId);
  }

  async onLoad(itemId) {
    const { configData = {} } = await ConfigurationService.getFullConfiguration();
    const { Item: itemConfig = {}, ItemType: itemTypeConfig = {} } = configData?.components?.item || {};
    const { tsPropertyConstraints: itemProperties = {} } = itemConfig;
    const { tsPropertyConstraints: itemTypeProperties = {} } = itemTypeConfig;

    const { detailsPage = {} } = configData.webClient?.appConfigurationMap?.asset;
    const {
      processStateHistory = false,
      processStates = [],
      remarksEnabled,
      modifyModal = {},
      useActivityLog,
      issueOptions = {},
      markAsCompleted = {},
      reuseTrackerSerial = true,
      routeOptions
    } = detailsPage || {};
    const { modifyModalTitle, isEnabled: isModifyModalEnabled, isReplaceTagEnabled } = modifyModal;

    const AssetClient = XemelgoService.getClient().getAssetClient();
    const ItemClient = XemelgoService.getClient().getItemClient();
    const solutionType = await ConfigurationService.getSolutionType();
    const solutionConfiguration = await ConfigurationService.getSolutionConfiguration();
    const availableFlags = (await ConfigurationService.getStatusConfiguration("asset")) || {};
    const attributeMap = (await ConfigurationService.getSolutionAttributeMap("asset")) || {};
    const locationCategories = await ConfigurationService.getDetectorLocationCategoriesForSolution("asset");
    const { defaultAttributeMap } = attributeMap;
    const shipmentsAppConfiguration = solutionConfiguration.uiPreferences.shipments;

    const showShipmentData =
      solutionType.includes("shipments") && shipmentsAppConfiguration.shipmentsTrackingItemClasses.includes("Asset");
    const locationRoleEnabled = configData?.features?.locationRole?.enabled;
    const additionalQueryAttributes = {
      ...defaultAttributeMap
    };

    if (remarksEnabled) {
      additionalQueryAttributes.comments = {
        tenantPropertyFor: "items"
      };
    }

    const assetDetails =
      (await AssetClient.getAssetDetailsById(
        itemId,
        showShipmentData,
        additionalQueryAttributes,
        locationRoleEnabled,
        !useActivityLog,
        issueOptions.issueHistoryEnabled
      )) || {};
    const { item = {}, shipmentHistory = [] } = assetDetails;
    const { is_active, route = [], processStateRoute = [], status_flags = [], data = {}, hasIssue } = item;
    const formattedProcessStateRoute = formatProcessStateRoute(processStateRoute, processStates);

    const { is_missing, state } = data;

    item.state = processStates.find((processState) => {
      return processState.id === state;
    });

    const remarks = item.customFields?.comments;
    const assetState = { ...item, is_missing, remarks };
    const assetAttributeList = getAttributeList(attributeMap, item);

    const titleStructure = {
      key: assetAttributeList[0].id,
      name: assetAttributeList[0].name,
      value: assetAttributeList[0].value,
      editable: assetAttributeList[0].editable
    };

    const contentStructureList = assetAttributeList.slice(1);
    if (is_active) {
      contentStructureList.push({
        key: "markAsMissingOrFound",
        name: is_missing ? "Mark as Found" : "Mark as Missing",
        type: "button",
        onClick: () => {
          this.setState({ showMissingOrFoundModal: true });
        }
      });
      if (markAsCompleted?.enabled) {
        contentStructureList.push({
          key: "markAsCompleted",
          name: markAsCompleted.buttonTitle || "Archive",
          type: "button",
          light: "true",
          onClick: () => {
            this.setState({ showMarkAsCompletedModal: true });
          }
        });
      }
    }

    const routeTitleList = [
      {
        key: "location",
        value: "Location"
      },
      {
        key: "entryTime",
        value: "Entry Time"
      },
      { key: "exitTime", value: "Exit Time" },
      { key: "timeDuration", value: "Time Duration" }
    ];
    const processStateRouteTitleList = [
      {
        key: "location",
        value: "Location"
      },
      { key: "state", value: "State" },
      {
        key: "eventTime",
        value: "Time"
      },
      { key: "timeDuration", value: "Time Duration" }
    ];
    const shipmentTitleList = [
      {
        key: "shipmentLocation",
        value: "Location"
      },
      {
        key: "eventTime",
        value: "Event Time"
      },
      { key: "eventDetails", value: "Event Details", extraData: true },
      { key: "detector", value: "Detector" },
      { key: "shipmentTimeDuration", value: "Time Duration" }
    ];

    route
      .sort((a, b) => {
        return b.entryTime - a.entryTime;
      })
      .forEach((each) => {
        const duration = each.exitTime
          ? msToTime(each.exitTime - each.entryTime)
          : msToTime(Date.now() - each.entryTime);

        each.timeDuration = duration;
        each.entryTime = getFormattedDate(each.entryTime, "hh:mm A MMM D");
        each.exitTime = each.exitTime ? getFormattedDate(each.exitTime, "hh:mm A MMM D") : "-";
      });

    if (status_flags.length > 0) {
      assetState.statusFlags = getStatusFlags(status_flags, availableFlags);
    }

    // Process event time and duration for each event
    if (showShipmentData) {
      shipmentHistory.forEach((row, index) => {
        const currEvent = row.eventTime;
        const prevEvent = shipmentHistory[index + 1] && shipmentHistory[index + 1].eventTime;
        row.shipmentTimeDuration = msToTime((prevEvent || Date.now()) - currEvent);
        row.eventTime = getFormattedDate(row.eventTime, "hh:mm A MMM D");
        const { condition, comment, submittedBy } = row.eventDetails;
        if (condition || comment || submittedBy) {
          row.extraData = [
            { key: "condition", value: "Condition", data: condition },
            { key: "comment", value: "Comment", data: comment },
            { key: "submittedBy", value: "Submitted By", data: submittedBy }
          ];
        }
        row.eventDetails = row.eventDetails.class;
      });
    }

    if (issueOptions.reportIssueEnabled) {
      contentStructureList.push({
        key: "reportIssue",
        name: "Report an Issue",
        type: "button",
        light: "true",
        onClick: () => {
          this.setState({ showReportIssueModal: true });
        }
      });
    }

    let assetInfoForReprint;
    // get asset information for reprint

    if (isModifyModalEnabled) {
      contentStructureList.push({
        key: "reprint",
        name: modifyModalTitle || this.state.modifyModalTitle,
        type: "button",
        onClick: () => {
          this.setState({ showReprintModal: true });
        }
      });

      const [asset] = await ItemClient.getItemsByIds(
        [assetState.id],
        ["id", "identifier", "name", "lot_number", "description", ...Object.keys(itemProperties)],
        ["identifier", "description", ...Object.keys(itemTypeProperties)]
      );
      const {
        id,
        identifier: item_identifier,
        name,
        description: item_description,
        ofType,
        hasSensorProfile,
        ...restOfItemAttributes
      } = asset.data || {};
      const { identifier: item_number, description, ...restOfItemTypeAttributes } = ofType[0] || {};
      const { vid, id: sensor_profile_id } = hasSensorProfile[0] || {};
      assetInfoForReprint = {
        id,
        identifier: item_identifier,
        name,
        item_description,
        item_number,
        description,
        tracker_serial: vid,
        sensor_profile_id,
        ...restOfItemAttributes,
        ...restOfItemTypeAttributes
      };
    }

    this.setState({
      itemId,
      assetState,
      showShipmentData,
      route,
      shipmentHistory,
      routeTitleList,
      shipmentTitleList,
      processStateRouteTitleList,
      titleStructure,
      contentStructureList,
      addLocation: false,
      locationToAdd: {},
      showDeleteAssetModal: false,
      loading: false,
      isSubmitting: false,
      processStateHistory,
      formattedProcessStateRoute,
      remarksEnabled,
      useActivityLog,
      issueOptions,
      availableFlags,
      modifyModalTitle,
      isModifyModalEnabled,
      isReplaceTagEnabled,
      assetInfoForReprint,
      issues: hasIssue,
      markAsCompleted,
      routeOptions: {
        locationCategories,
        ...routeOptions
      },
      reuseTrackerSerial,
      locationCategories
    });

    this.fetchLocations();
  }

  async fetchLocations() {
    const { assetState, useActivityLog, locationCategories } = this.state;

    if (useActivityLog) {
      return;
    }

    const LocationClient = XemelgoService.getClient().getLocationClient();
    const locations = ((await LocationClient.getLocationsOfCategory(locationCategories)) || []).sort(
      multipleSortComparison([{ id: "name" }])
    );

    const allLocations = locations.map((each) => {
      return {
        key: each.id,
        label: each.name,
        value: each.id
      };
    });

    const addToLocations = allLocations.filter((eachLocation) => {
      return eachLocation.label !== assetState.last_known_location;
    });

    this.setState({
      locations: addToLocations,
      allLocations
    });
  }

  async fetchIssues() {
    const { itemId, assetState, availableFlags, issueOptions } = this.state;
    const { statusFlags = [] } = assetState;

    const issueClient = XemelgoService.getClient().getIssueClient();
    const newIssues = await issueClient.getIssuesByItemId(itemId);

    const newStatusFlags = getStatusFlagsWithIssues(newIssues, availableFlags, statusFlags, issueOptions.issueTypes);

    this.setState({
      assetState: {
        ...assetState,
        statusFlags: newStatusFlags
      },
      issues: newIssues
    });
  }

  renderDeleteAssetModal = () => {
    const { showDeleteAssetModal, assetState, isSubmitting } = this.state;

    return (
      showDeleteAssetModal && (
        <Modal
          title="Delete Asset"
          onCancel={() => {
            this.setState({ showDeleteAssetModal: false });
          }}
          onConfirm={this.deleteAsset}
          confirmDisabled={isSubmitting}
          confirmButtonColor={xemelgoStyle.theme.STATUS_RED}
        >
          You are about to delete <span className={Style.bold_text}>{assetState.identifier}</span>. Please press
          "Confirm" to proceed.
        </Modal>
      )
    );
  };

  handleCommentBox = ({ currentTarget }) => {
    this.setState({ [currentTarget.id]: currentTarget.value });
  };

  renderMarkAsMissingOrFoundModal = () => {
    const { showMissingOrFoundModal, assetState, locationToAdd, allLocations, commentBoxText, isSubmitting } =
      this.state;

    return (
      showMissingOrFoundModal && (
        <Modal
          title={`Mark as ${assetState.is_missing ? "Found" : "Missing"}`}
          onCancel={() => {
            this.setState({
              showMissingOrFoundModal: false,
              locationToAdd: {},
              commentBoxText: ""
            });
          }}
          onConfirm={this.markAsMissingOrFound}
          confirmDisabled={isSubmitting || (assetState.is_missing && Object.keys(locationToAdd).length === 0)}
        >
          {!assetState.is_missing && (
            <p>
              You are about to mark
              <b style={{ fontWeight: "bold" }}>{` ${assetState.identifier} `}</b>
              as missing, are you sure you want to do this?
            </p>
          )}

          {assetState.is_missing && (
            <div>
              <InputLabel>{`Select location where ${assetState.identifier} was found`}</InputLabel>
              <SearchDropdown
                selectedItem={locationToAdd || {}}
                showIcon
                options={allLocations}
                onItemSelected={(event) => {
                  this.setState({
                    locationToAdd: event
                  });
                }}
              />

              {locationToAdd.value === "untrackedLocation" && (
                <div className={Style.commentBoxContainer}>
                  <InputLabel>Specify where asset was found (Optional)</InputLabel>
                  <TextareaAutosize
                    id="commentBoxText"
                    className={Style.detail_value_input}
                    value={commentBoxText}
                    onChange={this.handleCommentBox}
                  />
                </div>
              )}
            </div>
          )}
        </Modal>
      )
    );
  };

  renderAssetRoute = () => {
    const {
      assetState,
      route,
      routeTitleList,
      processStateRouteTitleList,
      processStateHistory,
      formattedProcessStateRoute,
      useActivityLog,
      routeOptions
    } = this.state;

    if (useActivityLog) {
      return (
        <ItemRouteFeature
          solution={SOLUTIONS.ASSET}
          itemId={assetState.id}
          currentLocationId={assetState.location?.id}
          itemIdentifier={assetState.identifier}
          options={routeOptions}
          onLocationAdded={() => {
            this.onLoad(assetState.id);
          }}
        />
      );
    }
    if (processStateHistory && formattedProcessStateRoute.length) {
      return (
        <DataTable
          titleList={processStateRouteTitleList}
          dataList={formattedProcessStateRoute}
        />
      );
    }
    return (
      <DataTable
        titleList={routeTitleList}
        dataList={route}
      />
    );
  };

  markAsMissingOrFound = async () => {
    const { itemId, locationToAdd, commentBoxText, assetState, allLocations } = this.state;
    const selectedLocation = allLocations.find((option) => {
      return option.value === locationToAdd.value;
    });
    this.setState({ isSubmitting: true });

    const contextPayload = {
      missing: {
        itemIdentifier: assetState.identifier,
        value: !assetState.is_missing,
        comment: commentBoxText,
        lastSeenLocation: assetState.is_missing ? selectedLocation.name : assetState.data.last_known_location
      }
    };

    if (!assetState.is_missing) {
      contextPayload.missing.flipConditions = "ManualOrAnyDetectionAtLocation";
    }

    const AssetClient = XemelgoService.getClient().getAssetClient();

    try {
      await AssetClient.markAssetAsMissingOrFound(
        itemId,
        locationToAdd.value === "untrackedLocation" ? null : locationToAdd.value,
        contextPayload
      );
    } catch (e) {
      window.alert(e);
    } finally {
      this.setState({
        commentBoxText: "",
        showMissingOrFoundModal: false
      });
      await this.onLoad(itemId);
    }
  };

  deleteAsset = async () => {
    const { itemId } = this.state;
    const AssetClient = XemelgoService.getClient().getAssetClient();

    this.setState({ isSubmitting: true });

    try {
      await AssetClient.deleteAsset(itemId);
    } catch (e) {
      console.log(e);
    } finally {
      window.history.back();
    }
  };

  renderAddLocationModal = (addLocation) => {
    const { locations, locationToAdd, isSubmitting } = this.state;

    return (
      addLocation && (
        <Modal
          title="Add Location"
          onCancel={() => {
            this.setState({
              locationToAdd: {},
              addLocation: !addLocation
            });
          }}
          onConfirm={this.handleAddLocation}
          confirmDisabled={isSubmitting || Object.keys(locationToAdd).length === 0}
        >
          <InputLabel>Select a location</InputLabel>
          <SearchDropdown
            selectedItem={locationToAdd || {}}
            showIcon
            options={locations}
            onItemSelected={(event) => {
              this.setState({
                locationToAdd: event
              });
            }}
          />
        </Modal>
      )
    );
  };

  handleAddLocation = async () => {
    const { itemId, locationToAdd } = this.state;
    const publishClient = XemelgoService.getClient().getPublishClient();
    try {
      this.setState({ isSubmitting: true });
      await publishClient.publishUserEvent([itemId], locationToAdd.value);
    } catch (e) {
      console.log(e);
    } finally {
      await this.onLoad(itemId);
    }
  };

  handleSubmitDetail = async (dataForm) => {
    const { itemId, assetState, reuseTrackerSerial } = this.state;
    if (Object.keys(dataForm).length !== 0) {
      try {
        this.setState({ isSubmitting: true });
        await updateAssetDetails(itemId, assetState.identifier, dataForm, reuseTrackerSerial);
        this.setState({
          isSubmitting: false,
          popupStatus: STATUS_OPTIONS.SUCCESS,
          popupMessage: "Successfully updated asset details."
        });
        await this.onLoad(itemId);
      } catch (e) {
        this.setState({
          isSubmitting: false,
          popupStatus: STATUS_OPTIONS.ERROR,
          popupMessage: "Failed to update asset details. Please try again later or contact Xemelgo for support."
        });
      }
    }
  };

  handleSubmitImages = async (imagesFilesToUpload, imagePathsToRemove) => {
    if (!imagesFilesToUpload.length && !imagePathsToRemove.length) {
      return;
    }
    const { itemId, tenantId } = this.state;
    const AssetClient = XemelgoService.getClient().getAssetClient();
    this.setState({ isSubmitting: true });

    const propertiesToUpdate = {
      images: {
        flagsToAdd: [],
        flagsToRemove: []
      }
    };

    try {
      if (imagesFilesToUpload.length) {
        propertiesToUpdate.images.flagsToAdd = await uploadImagesToS3(imagesFilesToUpload, tenantId, "asset");
      }
      if (imagePathsToRemove.length) {
        propertiesToUpdate.images.flagsToRemove = imagePathsToRemove;
        // Should remove the images from S3 but this is not allowed from the client.
      }
      await AssetClient.updateAsset(itemId, propertiesToUpdate);
    } catch (e) {
      console.error(e);
      window.alert(e);
    } finally {
      await this.onLoad(itemId);
    }
  };

  handleSubmitRemarks = async (remarks) => {
    const { assetState, itemId } = this.state;
    const AssetClient = XemelgoService.getClient().getAssetClient();

    try {
      this.setState({ assetState: { ...assetState, remarks } });
      await AssetClient.updateAsset(itemId, {
        comments: remarks
      });
    } catch (e) {
      window.alert(e);
    } finally {
      this.onLoad(itemId);
    }
  };

  getAdditionalMoreButtonOptions = () => {
    return (
      <DropdownItem
        className="dropdown-item"
        onClick={() => {
          this.setState({ showDeleteAssetModal: true });
        }}
        data-cy-asset-detail-card__more-button-option
      >
        <div className={Style.red_text}>Delete Asset</div>
      </DropdownItem>
    );
  };

  render() {
    const {
      titleStructure,
      contentStructureList,
      assetState,
      showShipmentData,
      shipmentHistory,
      shipmentTitleList,
      addLocation,
      loading,
      isSubmitting,
      assetInfoForReprint,
      showReprintModal,
      showReportIssueModal,
      issueOptions,
      issues,
      modifyModalTitle,
      isReplaceTagEnabled,
      popupStatus,
      popupMessage,
      allLocations,
      showMarkAsCompletedModal,
      markAsCompleted,
      useActivityLog
    } = this.state;
    if (loading) {
      return <LoadingCircle />;
    }

    if (!assetState?.id) {
      return (
        <NotFoundComponent
          path="/asset"
          buttonLabel="Go To Track Page"
          mainText="Sorry, the asset you have requested has been deleted."
          subText="Please click the following button to go back to track page."
        />
      );
    }

    return (
      <>
        <ScreenFrame
          title="Asset Details"
          color={xemelgoStyle.theme.ASSET_PRIMARY}
          secondaryColor={xemelgoStyle.theme.ASSET_SECONDARY}
          titleIconComponent={
            <AssetIcon
              width={25}
              height={25}
              style={{ color: xemelgoStyle.theme.ASSET_PRIMARY }}
            />
          }
        >
          <div className={Style.content_holder}>
            <div className={Style.detail_group}>
              <DetailCard
                defaultImage={assetState.type && assetState.type.imagePath}
                images={assetState.images || []}
                isLoading={isSubmitting}
                isActive={assetState.is_active}
                statusList={assetState.statusFlags}
                titleStructure={titleStructure}
                detailStructureList={contentStructureList}
                onSubmit={this.handleSubmitDetail}
                onSubmitImages={this.handleSubmitImages}
                getAdditionalMoreButtonOptions={this.getAdditionalMoreButtonOptions}
                remarks={assetState.remarks}
                remarksEnabled={this.state.remarksEnabled}
                onRemarksSubmit={this.handleSubmitRemarks}
              />
            </div>
            <div className={Style.table_group}>
              {issueOptions.issueHistoryEnabled && !!issues?.length && (
                <div className={Style.top_table}>
                  <IssueHistoryFeature
                    itemIdentifier={assetState.identifier}
                    issueOptions={issueOptions}
                    issues={issues}
                    onIssueUpdate={(status, message) => {
                      this.fetchIssues();
                      this.setState({
                        popupStatus: status,
                        popupMessage: message
                      });
                    }}
                  />
                </div>
              )}
              <div className={Style.top_table}>
                <div className={Style.title_group}>
                  <div className={Style.title_label}>Asset Route</div>
                  {assetState.is_active && !useActivityLog && (
                    <div
                      role="button"
                      tabIndex={-1}
                      onClick={() => {
                        return this.setState({ addLocation: !addLocation });
                      }}
                      className={Style.part_edit_btn}
                    >
                      Add Location
                    </div>
                  )}
                </div>
                {this.renderAssetRoute()}
              </div>
              {showShipmentData && (
                <div className={Style.bottom_table}>
                  <div className={Style.title_group}>
                    <div className={Style.title_label}>Shipping History</div>
                  </div>
                  <DataTable
                    titleList={shipmentTitleList}
                    dataList={shipmentHistory}
                  />
                </div>
              )}
            </div>
          </div>
        </ScreenFrame>
        {this.renderAddLocationModal(addLocation)}
        {this.renderMarkAsMissingOrFoundModal()}
        {showReprintModal && (
          <ModifyItemModal
            selectedItem={assetInfoForReprint}
            fieldsToUpdate={contentStructureList
              .filter((each) => {
                return each.replaceable;
              })
              .map((item) => {
                const { name } = item;
                return { ...item, label: name };
              })}
            modalTitle={modifyModalTitle}
            replaceTagEnabled={isReplaceTagEnabled}
            appId="asset"
            onCloseModal={(newSubmitStatus = STATUS_OPTIONS.NONE) => {
              this.componentDidMount();
              this.setState({
                popupStatus: newSubmitStatus,
                popupMessage:
                  newSubmitStatus === STATUS_OPTIONS.SUCCESS
                    ? "The tag has been replaced and submitted for printing."
                    : "Failed to replace tag. Please contact Xemelgo Support for assistance.",
                showReprintModal: false
              });
            }}
          />
        )}
        {this.renderDeleteAssetModal()}
        {showReportIssueModal && (
          <ReportIssueModal
            itemId={assetState.id}
            formFields={issueOptions.formFields}
            onClose={(status, message) => {
              this.setState({
                showReportIssueModal: false,
                popupStatus: status,
                popupMessage: message
              });
              this.fetchIssues();
            }}
            locations={allLocations}
            possibleIssueTypes={issueOptions.issueTypes}
            defaultLocationId={assetState.location?.id}
          />
        )}
        {showMarkAsCompletedModal && (
          <MarkAsCompletedActionModal
            title={markAsCompleted?.modalTitle || "Archive Asset"}
            body={
              markAsCompleted?.modalBody || 'Archived assets will no longer be tracked. Press "Confirm" to proceed.'
            }
            onCloseModal={() => {
              this.setState({ showMarkAsCompletedModal: false });
            }}
            onPostConfirm={(error) => {
              this.setState({
                showMarkAsCompletedModal: false,
                popupStatus: !error ? STATUS_OPTIONS.SUCCESS : STATUS_OPTIONS.ERROR,
                popupMessage: !error
                  ? markAsCompleted?.successMessage || "Successfully archived the asset."
                  : markAsCompleted?.failureMessage ||
                    "Failed to archive the asset. Please try again later or contact Xemelgo for support."
              });
              if (!error) {
                this.onLoad(assetState.id);
              }
            }}
            itemIds={[assetState.id]}
            detachSensorProfiles={markAsCompleted?.detachSensorProfiles}
          />
        )}
        {popupStatus && popupStatus !== STATUS_OPTIONS.NONE && (
          <StatusPopupComponent
            status={popupStatus}
            message={popupMessage}
            durationMs={DEFAULT_POPUP_DURATION_MS}
            closeOnClickOutside
            onPopupClose={() => {
              this.setState({ popupStatus: STATUS_OPTIONS.NONE });
            }}
            showPopup
            isCenterPopup
          />
        )}
      </>
    );
  }
}
