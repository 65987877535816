import React, { useCallback, useState } from "react";
import PaginatedListTableWithTypes, { Header, Item } from "../../../../components/paginated-list-table-with-types";
import Style from "./DetailsPageTable.module.css";
import { PaginatedListTableWithTypesProps } from "../../../../components/paginated-list-table-with-types/PaginatedListTableWithTypes";
import FreeTextSearchInput from "../../../../components/free-text-search-input";
import { ReactComponent as ExportIcon } from "../../../../assets/icons/export.svg";
import useFilteredTableData from "../../../../hooks/use-filtered-table-data";
import createCsv from "../../../../components/paginated-list-table-with-types/utils/create-csv";

interface DetailsPageTableProps extends Omit<PaginatedListTableWithTypesProps, "dataList"> {
  data: Item[];
  isLoading?: boolean;
  headers: Header[];
  isExpandable?: boolean;
  searchPlaceholder?: string;
  exportCsv?: (filteredData: Item[]) => void;
  csvFileName?: string;
  pageSize?: number;
  textSearchEnabled?: boolean;
  exportEnabled?: boolean;
}

export const DetailsPageTable = ({
  data,
  isLoading,
  headers,
  isExpandable,
  searchPlaceholder = "Type to filter",
  exportCsv,
  csvFileName = "Details",
  pageSize,
  textSearchEnabled = true,
  exportEnabled = true,
  ...props
}: DetailsPageTableProps) => {
  const [searchInput, setSearchInput] = useState("");
  const { filteredData } = useFilteredTableData(data, headers, searchInput);

  const defaultExportCsv = useCallback(() => {
    createCsv(filteredData, headers, csvFileName);
  }, [filteredData, headers, csvFileName]);

  return (
    <div className={Style.container}>
      {(exportEnabled || textSearchEnabled) && (
        <div className={Style.options_container}>
          {textSearchEnabled && (
            <div className={Style.free_text_search_container}>
              <FreeTextSearchInput
                value={searchInput}
                onChangeText={setSearchInput}
                searchInputClassName={Style.free_text_search_input}
                placeholder={searchPlaceholder}
              />
            </div>
          )}
          {exportEnabled && (
            <button
              type="button"
              className={`${Style.button} ${Style.white_button} ${Style.export_button}`}
              onClick={() => {
                if (exportCsv) {
                  exportCsv(filteredData);
                } else {
                  defaultExportCsv();
                }
              }}
            >
              <ExportIcon className={Style.export_icon} />
              Export
            </button>
          )}
        </div>
      )}
      <PaginatedListTableWithTypes
        dataList={filteredData}
        isLoading={isLoading}
        headers={headers}
        isExpandable={isExpandable}
        headerRowContainerClassName={Style.header_row_container}
        headerCellContainerClassName={Style.table_header}
        itemCellContainerClassName={Style.table_item_container}
        itemRowContainerClassName={isLoading ? Style.loading_table_item_row : Style.table_item_row}
        emptyListContainerClassName={Style.empty_list_container}
        paginatorLocation="top"
        numItemsPerPage={pageSize}
        {...props}
      />
    </div>
  );
};
