import { XemelgoService } from "../../../../services/XemelgoService";
import {
  AdditionalAttributes,
  DetailsCardConfig,
  DetailsCardField
} from "../../../details-page/contexts/details-page-config-context/data/types";
import { TRANSFER_STATUS_MAP } from "../../../kiosk/features/selected-kiosk-feature/features/transfer-order-actions/data/constants";
import { DetailCardItemProperty, DetailsCardData } from "../../../details-page/features/details-card/data/types";
import { TransferOrderItemType } from "../../data/types";
import { TransferOrder } from "./data/types";
import parseItemEntryItems from "./utils/parse-item-entry-items";

export const fetchTransferOrder = async (
  transferOrderId: string,
  additionalAttributes: AdditionalAttributes,
  detailsCardConfig: DetailsCardConfig
) => {
  const transferClient = XemelgoService.getClient().getTransferClient();

  const transferOrder: TransferOrder = (
    await transferClient.getTransferOrdersByIds([transferOrderId], additionalAttributes)
  )[0];

  if (!transferOrder) {
    throw new Error(`Transfer order with id "${transferOrderId}" not found`);
  }

  const parsedItemTypes: TransferOrderItemType[] = transferOrder.hasItemsEntry.map((itemsEntry) => {
    const {
      quantity_total: quantityTotal = 0,
      quantity_created: quantityCreated = 0,
      quantity_received: quantityReceived = 0,
      quantity_staged: quantityStaged = 0,
      quantity_in_progress: quantityShipped = 0,
      quantity_verified: quantityVerified = 0,
      ofItemType: [itemType]
    } = itemsEntry;

    const { image_path: imagePath, identifier, name } = itemType || {};

    return {
      ...itemsEntry,
      ...itemType,
      identifierWithImage: {
        imageSrc: imagePath,
        text: identifier
      },
      nameWithImage: {
        imageSrc: imagePath,
        text: name
      },
      quantityTotal,
      quantityCreated,
      quantityStaged,
      quantityShipped,
      quantityReceived,
      quantityVerified,
      items: parseItemEntryItems(itemsEntry)
    };
  });

  return {
    id: transferOrder.id,
    identifier: transferOrder.identifier,
    itemTypes: parsedItemTypes,
    detailsCardData: parseDetailCardDetails(transferOrder, detailsCardConfig)
  };
};

const parseDetailCardDetails = (details: TransferOrder, detailsCardConfig: DetailsCardConfig): DetailsCardData => {
  const fromLocation = details.transferFrom?.[0];
  const toLocation = details.transferTo?.[0];

  const augmentedDetails = {
    ...details,
    fromLocationName: fromLocation?.name,
    fromLocationIdentifier: fromLocation?.identifier,
    toLocationName: toLocation?.name,
    toLocationIdentifier: toLocation?.identifier
  };

  const status = TRANSFER_STATUS_MAP[details.transfer_status];

  return {
    id: details.id,
    name: details.identifier,
    identifier: details.identifier,
    statuses: [status],
    imageUrls: [],
    displayedProperties: parseProperties(augmentedDetails, detailsCardConfig.fields)
  };
};

const parseProperties = (details: any, propertiesToDisplay: DetailsCardField[]): DetailCardItemProperty[] => {
  return propertiesToDisplay.reduce((acc: DetailCardItemProperty[], property) => {
    if (details[property.key]) {
      acc.push({
        ...property,
        name: property.label,
        value: details[property.key]
      });
    }

    return acc;
  }, []);
};
