import React, { useMemo } from "react";
import { DEFAULT_TRANSFER_ORDER_CONTAINERS_TABLE_HEADERS } from "./data/constants";
import { Header, Item } from "../../../../components/paginated-list-table-with-types";
import { DetailsPageTable } from "../../../details-page";
import { TransferOrderItemType } from "../../data/types";
import { ITEM_CLASS_TO_URL_PATH } from "../transfer-order-item-types-table/data/constants";

type TransferOrderContainersTableOptions = {
  headers?: Header[];
  csvFileName?: string;
  pageSize?: number;
};

export const TransferOrderContainersTable = ({
  itemTypes,
  transferOrderIdentifier,
  options
}: {
  options: TransferOrderContainersTableOptions;
  itemTypes: TransferOrderItemType[];
  transferOrderIdentifier: string;
}) => {
  const {
    csvFileName = "Container Details",
    headers = DEFAULT_TRANSFER_ORDER_CONTAINERS_TABLE_HEADERS,
    pageSize
  } = options || {};

  const containers: Item[] = useMemo(() => {
    return itemTypes.reduce((acc: Item[], itemType) => {
      const { items } = itemType;

      items.forEach((item) => {
        const { class: itemClass } = item || { hasSensorProfile: [] };

        if (itemClass !== "Container") {
          return;
        }

        acc.push(item);
      });

      return acc;
    }, []);
  }, [itemTypes]);

  return (
    <DetailsPageTable
      paginatorLocation="top"
      headers={headers}
      data={containers}
      getTitleURLCallbackFn={(item: any) => {
        const path = ITEM_CLASS_TO_URL_PATH[item.class];
        if (path) {
          return `/${path}/detail/container?id=${item.id}`;
        }

        return "";
      }}
      sortIdReplacementMap={{
        status: "statusSortValue"
      }}
      csvFileName={`${csvFileName} - ${transferOrderIdentifier}`}
      pageSize={pageSize}
    />
  );
};
