import React, { createContext, useContext, useMemo } from "react";
import { useAppConfigProvider } from "../../../../services/soft-cache-service";
import { SOLUTION_CONFIGS } from "./data/constants";
import { DetailsPageConfigType } from "./data/types";
import getDefaultedConfig from "./utils";
import { FEATURES } from "../../../../data/constants";

const defaultConfig: DetailsPageConfigType = {
  additionalAttributes: {
    item: [],
    itemType: ["image_path"]
  },
  customText: {
    title: "Details",
    subtitle: "Name"
  },
  detailsCard: {
    fields: [
      {
        key: "node_last_updated_time",
        label: "Last Updated",
        editable: false,
        type: "date"
      },
      {
        key: "node_created_time",
        label: "Created",
        editable: false,
        type: "date"
      }
    ]
  },
  tabs: [],
  subfeatureOptions: {}
};

const DetailsPageConfigContext = createContext<DetailsPageConfigType>(defaultConfig);

export const useDetailsPageConfigContext = () => {
  return useContext(DetailsPageConfigContext);
};

export const DetailsPageConfigContextProvider = ({
  children,
  solutionId
}: {
  children: React.ReactNode;
  solutionId: string;
}) => {
  const configProvider = useAppConfigProvider(solutionId);

  const configValues = useMemo(() => {
    const detailsPageConfig = configProvider.getValue(FEATURES.DETAILS_PAGE, "object") || {};
    const solutionDefaultConfig = SOLUTION_CONFIGS[solutionId] || defaultConfig;
    return getDefaultedConfig(solutionDefaultConfig, detailsPageConfig);
  }, [configProvider]);

  return <DetailsPageConfigContext.Provider value={configValues}>{children}</DetailsPageConfigContext.Provider>;
};
